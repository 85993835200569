import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BasePage from 'components/BasePage'
import AutoDetail from './AutoDetail'
import HabDetail from './HabDetail'
import BillingDisplay from 'account/BillingDisplay'
import LoadingSpinner from 'components/LoadingSpinner'
import Tile from 'components/Tile'
import Divider from 'components/Divider'
import NotFound from './NotFound'
import { policyDate } from 'utils/helpers'
import { AppContext, AccountContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

import './style.scss'

const PolicyDetail = () => {

  const { t, i18n } = useTranslation()
  const { policyNumber } = useParams()

  const [carrierImg, setCarrierImg] = useState(null)

  const { policies, billing } = useContext(AccountContext)
  const { tenant } = useContext(AppContext)

  const singlePol = policies.find(p => p.policy_number === policyNumber)
  
  const carrierLogosFR = ['HAL', 'HAL3', 'WU', 'GC', 'GC3']
  
  const billingDetailsEnabled = false

  useEffect(() => {
    if (singlePol) {
      if(i18n.language === 'fr' && carrierLogosFR.includes(singlePol.carrier_code)){
        import(`utils/carriers/FR/${singlePol.carrier_code}_FR.jpg`)
        .then(data => setCarrierImg(data.default))
        .catch(() => {
          import(`utils/carriers/EN/${tenant.slug}.jpg`)
            .then(data => setCarrierImg(data.default))
        })
      } else {
        import(`utils/carriers/EN/${singlePol.carrier_code}.jpg`)
        .then(data => setCarrierImg(data.default))
        .catch(() => {
          import(`utils/carriers/EN/${tenant.slug}.jpg`)
            .then(data => setCarrierImg(data.default))
        })
      }
    } else {
      import(`utils/carriers/EN/${tenant.slug}.jpg`)
        .then(data => setCarrierImg(data.default))
    }
  }, []) // eslint-disable-line

  if (policies.length < 1) {
    return (
      <div className="container">
        <div className="LoadingHolder">
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (singlePol === undefined) {
    return <NotFound />
  }

  return (
    <BasePage className="PolicyDetail">
      {billingDetailsEnabled && <div className="DetailBilling">
        <div className="container">
          <BillingDisplay
            policy={singlePol}
            data={billing[singlePol.id]}
            InDetail={true}
          />
        </div>
        <Divider Small />
      </div>}
      <div className="PolicyDetail container">
        <Tile className="DetailInfo">
          <div className="TileHeader">
            <strong>{t('Policy')} #{singlePol.policy_number}</strong>
          </div>
          <div className="TileBody clearfix PolicyDetailDates">
            <div className="Date">
              <small className="subtle">{t('Effective')}</small>
              <span>{policyDate(singlePol, 'effective_date')}</span>
            </div>
            <div className="Date">
              <small className="subtle">{t('Expires')}</small>
              <span>{policyDate(singlePol, 'expiration_date')}</span>
            </div>
            <div className="CarrierLogo">
              <img width="100" src={carrierImg} alt="Carrier Logo" />
            </div>
          </div>
        </Tile>
        {singlePol.lob === 'auto' && (
          <AutoDetail policy={singlePol} />
        )}
        {singlePol.lob === 'hab' && (
          <HabDetail policy={singlePol} />
        )}
      </div>
    </BasePage>
  )
}

export default PolicyDetail
