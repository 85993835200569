import React, { useContext, useEffect, useState } from 'react'
import { AccountContext, FormContext } from 'utils/context'
import Text from 'forms/fields/Text'
import { useTranslation } from 'react-i18next'
import { additionalInterests, autoLienholderChoices, propertyLienholderChoices } from 'forms/utils/vars'
import Tile from 'components/Tile'
import Select from 'forms/fields/Select'
import TypeAhead from 'forms/fields/TypeAhead'
import CompanyIcon from 'utils/icons/Company'
import { provinceChoices } from 'utils/vars'
import Radio from 'forms/fields/Radio'
import PolicyNamedInsured from 'forms/fields/PolicyNamedInsured'
import HelpCircleButton from 'forms/fields/HelpCircleButton'
import ArrayRadio from 'forms/fields/ArrayRadio'

const PolicyLienholder = ({policyId, lienholderData}) => {
  const { t } = useTranslation()
  const { policies, vehicles, locations } = useContext(AccountContext)
  const { data, clearAllErrors, setFieldValue } = useContext(FormContext)
  
  const [showOtherCompany, setShowOtherCompany] = useState(false)
  const [showPrivateLender, setPrivateLender] = useState(false)
  const [vehSelectLabel, setVehSelectLabel] = useState('')
  const [homeSelectLabel, setHomeSelectLabel] = useState('')

  const policy = policies.find(p => p.policy_id === policyId)
  const policyLob = policy.lob
  const policyNumber = policy.policy_number
  const fieldName = name => policyId ? `${policyId}_${name}` : name
  
  const strings = additionalInterests.map(i => i.Name)
  const matched = additionalInterests.find(a => a.Name === data['financing_company'])
  
  let isOtherNamedInsured;
  if (lienholderData['named_insured']?.length) {
    isOtherNamedInsured = lienholderData["named_insured"] ? lienholderData["named_insured"].includes("other") : null
  } else {
    isOtherNamedInsured = data[fieldName("named_insured")] ? data[fieldName("named_insured")].includes("other") : null
  }
  
  const policyVehicles = vehicles.filter(l => l.policy === policy.id)
  let vehChoices = []
  policyVehicles.forEach(v => {
    vehChoices.push([`${v.vehicle_id}`, `${v.full_vehicle_name}`])
  })
  
  const policyLocations = locations.filter(l => l.policy === policy.id)
  let habChoices = []
  policyLocations.forEach(l => {
    let city = l.city || ''
  
    if (city.includes(',')) {
      city = city.split(',')[0]
    }

    let province = l.province ? l.province : ''
    let postal = l.postal_code ? l.postal_code : ''
    habChoices.push([`${l.location_id}`, `${l.address_one}, ${city} ${province} ${postal}`])
  })

  const dataTypeQ = data.type === 'lienholder_remove' ? t('What would you like to remove from this policy?') : t('What would you like to add to this policy?')

  const lienholderCallback = value => {
    if (value === 'private_lender') {
      setPrivateLender(true)
      setFieldValue(fieldName('other_company'), null)
      setFieldValue(fieldName('company_street_number'), null)
      setFieldValue(fieldName('company_unit_number'), null)
      setFieldValue(fieldName('company_street_name'), null)
      setFieldValue(fieldName('company_city'), null)
      setFieldValue(fieldName('company_province'), null)
      setFieldValue(fieldName('company_postal_code'), null)
      setFieldValue(fieldName('financing_company'), null)
      setFieldValue(fieldName('financing_address'), null)
      setFieldValue(fieldName('financing_municipality'), null)
      setFieldValue(fieldName('financing_province'), null)
      setFieldValue(fieldName('financing_postal_code'), null)
    } else {
      setPrivateLender(false)
      setFieldValue(fieldName('private_lender_name'), null)
      setFieldValue(fieldName('private_lender_address'), null)
    }
  }

  const setTypeAheadValue = value => {
    const match = additionalInterests.find(a => a.Name === value)
    setFieldValue(fieldName('financing_company'), value)
    setFieldValue(fieldName('financing_address'), match.Address)
    setFieldValue(fieldName('financing_municipality'), match.City)
    setFieldValue(fieldName('financing_province'), match.Province)
    setFieldValue(fieldName('financing_postal_code'), match.PostalCode)
  }

  const otherCompanyCallback = () => {
    if (showOtherCompany) {
      clearAllErrors()
      setShowOtherCompany(!showOtherCompany)
      setFieldValue(fieldName('other_company'), null)
      setFieldValue(fieldName('company_street_number'), null)
      setFieldValue(fieldName('company_unit_number'), null)
      setFieldValue(fieldName('company_street_name'), null)
      setFieldValue(fieldName('company_city'), null)
      setFieldValue(fieldName('company_province'), null)
      setFieldValue(fieldName('company_postal_code'), null)
      
    } else {
      clearAllErrors()
      setShowOtherCompany(!showOtherCompany)
      setFieldValue(fieldName('financing_company'), null)
    }
  }
  
  useEffect(() => {
    if (data[fieldName('other_company')] || lienholderData['other_company']) {
      setShowOtherCompany(true)
    }
    setFieldValue(fieldName('has_other_company'), showOtherCompany)
  }, [data[fieldName('other_company')], showOtherCompany, lienholderData['other_company']])  // eslint-disable-line
  
  const hasNullValues = (obj, keysToCheck) => {
    return keysToCheck.some(key => obj[key] === null);
  }
  
  useEffect(() => {
    if (data.type === 'lienholder_remove') {
      setVehSelectLabel(t('Confirm the vehicle removing a lienholder or lessor.'))
      setHomeSelectLabel(t('Confirm the address removing a mortgage or line of credit.'))
    } else if (data.type === 'lienholder_add') {
      setVehSelectLabel(t('Confirm the vehicle we are adding a lienholder or lessor to.'))
      setHomeSelectLabel(t('Confirm the address we are adding a mortgage or line of credit to.'))
    } else {
      setVehSelectLabel(t('Confirm the vehicle that has a change to the lienholder or lessor.'))
      setHomeSelectLabel(t('Confirm the address that has a change to the mortgage, line of credit or private loan.'))
    }
    
    let nullValues = []
    if (policyLob === 'auto') {
      nullValues = ['vehicle_choices', 'named_insured']
    } else {
      nullValues = ['home_choices', 'named_insured']
    }
    if (!lienholderData['other_company'] && lienholderData['lienholder_choices'] !== 'private_lender') {
      nullValues.push('financing_company')
    }
    if (!hasNullValues(lienholderData, nullValues)) {
      setFieldValue(fieldName('vehicle_choices'), lienholderData['vehicle_choices'])
      setFieldValue(fieldName('home_choices'), lienholderData['home_choices'])
      setFieldValue(fieldName('named_insured'), lienholderData['named_insured'])
      setFieldValue(fieldName('named_insured_other'), lienholderData['named_insured_other'])
      if (nullValues.includes('financing_company')) {
        setTypeAheadValue(lienholderData['financing_company'])
      }
    }
  }, []) // eslint-disable-line

  return (
    <fieldset>
      <Text name={fieldName("lob")} DefaultValue={policyLob} Hidden />
      <strong>{t('POLICY')} {policyNumber}</strong>
      {policyLob === 'auto' ? (
        <>
          <ArrayRadio
            name={fieldName("vehicle_choices")}
            label={vehSelectLabel}
            choices={vehChoices}
            helpCopy={t('If you have multiple vehicles affected by this change, please submit a change request for each.')}
            Stacked
          />
          <PolicyNamedInsured
            name={fieldName("named_insured")}
            label={t("Who are the named insured(s) of this policy?")}
            policyId={policy.id}
            lienData={lienholderData}
            Other
          />
        </>
      ) : (
        <>
          <ArrayRadio
            name={fieldName("home_choices")}
            label={homeSelectLabel}
            choices={habChoices}
            helpCopy={t('If you have multiple properties affected by this change, please submit a change request for each.')}
            Stacked
          />
          <Text
            name={fieldName("named_insured")}
            label={t("Who are the named insured(s) of this policy?")}
            DefaultValue={lienholderData['named_insured']}
          />
        </>
      )} 
      {isOtherNamedInsured &&
        <Text
          name={fieldName("named_insured_other")}
          label={t('Other')}
          DefaultValue={lienholderData['named_insured_other']}
        />
      }
      <div className="radio-help-container">
        <Radio 
          name={fieldName("lienholder_choices")}
          label={dataTypeQ} 
          choices={policyLob === 'auto' ? autoLienholderChoices : propertyLienholderChoices}
          ChangeCallback={lienholderCallback}
          DefaultValue={lienholderData['lienholder_choices']}
          Stacked
        />
        <div className='help-button-wrapper'>
          {policyLob === 'auto' ? 
          <HelpCircleButton slug="lienholder-auto-help-copy"/> : <HelpCircleButton slug="lienholder-home-help-copy"/>}
        </div>
        
      </div>

      {!showPrivateLender && (
        <>
          <TypeAhead
            className="finance-company-search"
            name={fieldName("financing_company")}
            label={t("Lender's Name")}
            stringList={strings}
            setTypeAheadValue={setTypeAheadValue}
            DefaultValue={lienholderData['financing_company']}
          />
          <span style={{cursor: 'pointer', textDecorationLine: 'underline'}} onClick={otherCompanyCallback}>
            {showOtherCompany? t('(Show search field)') : t("(Can't find what you're looking for?)")}
          </span>
          {showOtherCompany && (
            <div>
              <b>{t('Please enter the name and address of your lender below')}</b>
              <Text name={fieldName("other_company")} label={t("Other Lender's Name")} DefaultValue={lienholderData['other_company']}/>
              <Text name={fieldName("company_street_number")} label={t('Street number')} DefaultValue={lienholderData['company_street_number']}/>
              <Text name={fieldName("company_unit_number")} label={t('Unit number')} DefaultValue={lienholderData['company_unit_number']} Optional/>
              <Text name={fieldName("company_street_name")} label={t('Street name')} DefaultValue={lienholderData['company_street_name']}/>
              <Text name={fieldName("company_city")} label={t('City')} DefaultValue={lienholderData['company_city']}/>
              <Select
                name={fieldName("company_province")}
                label={t('Province')}
                choices={provinceChoices}
                DefaultValue={lienholderData['company_province']}
              />
              <Text name={fieldName("company_postal_code")} label={t('Postal code')} DefaultValue={lienholderData['company_postal_code']}/>
            </div>
          )}
          {matched && (
            <Tile Icon={CompanyIcon} IconName="CompanyIcon">
              <div className="TileHeader">
              <strong>
                {t('Company address')}
              </strong>
              </div>
              <div className="TileBody">
                <address>
                  {matched.Name}<br />
                  {matched.Address}<br />
                  {matched.City}, {matched.Province}<br />
                  {matched.PostalCode}
                </address>
              </div>
            </Tile>
          )}
        </>
      )}
      {showPrivateLender && (
        <>
          <Text
            name={fieldName("private_lender_name")}
            label={t("Lender's Name")}
            DefaultValue={lienholderData['private_lender_name']}
          />
          <Text
            name={fieldName("private_lender_address")}
            label={t("Lender's Address")}
            DefaultValue={lienholderData['private_lender_address']}
          />
        </>
      )}
    </fieldset>
  )
}


export default PolicyLienholder
