import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import SimpleForm from 'forms/SimpleForm'
import Text from 'forms/fields/Text'
import DoublePassword from 'forms/fields/DoublePassword'
import SignupError from './SignupError'
import TenantUrl from 'components/TenantUrl'
import { emailAddress, lettersNumbersOnly } from 'forms/utils/cleans'
import { AppContext } from 'utils/context'
import { useLocation } from 'react-router-dom'
import { useTranslation, Trans} from 'react-i18next'
import LoadingSpinner from 'components/LoadingSpinner'
import './style.scss'

const SignupForm = () => {

  const { t } = useTranslation()
  const history = useHistory()
  let location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { tenant, showMaintenance } = useContext(AppContext)

  const signupSuccess = res => {
    if (res.email) history.push('/registered')
  }

  const signupFailure = (res, setFormError) => setFormError(<SignupError/>)

  const isPc = tenant && tenant.slug === 'cis'

  const emailHelp = <span>{isPc ? <Trans i18nKey='cis::callPCTenant' components={{phone: <TenantUrl Phone />}}/> : <Trans i18nKey='contactWebChat' />}</span>
  
  const email = searchParams.get('email')
  const policy = searchParams.get('policy')
  
  return (
    <div className={`login-form-wrapper ${showMaintenance === null ? 'is-loading' : ''}`}>
      {showMaintenance === null && (
        <div className="LoadingHolder login-overlay"><LoadingSpinner /></div>
      )}
      <SimpleForm SubmitUrl="/api/users/create/" SubmitLabel={t('Submit')} SuccessCallback={signupSuccess} ErrorCallback = {signupFailure}>
        <Text
          name="register_email"
          label={t('Email Address')}
          cleans={[emailAddress]}
          HelpCopy={emailHelp}
          autocomplete="username"
          DefaultValue={email}
        />
        <Text
          name="register_policy_number"
          label={t('Policy Number')}
          cleans={[lettersNumbersOnly]}
          HelpCopy = {t('Policy number can be found on your pink liability slip or on the first page of your policy documents. If this is a new policy, please allow 1-3 business days for your policy number to be generated.')}
          DefaultValue={policy}
        />
        <DoublePassword
          pw1="register_password1"
          pw2="register_password2"
        />
      </SimpleForm>
    </div>
  )
}

export default SignupForm