import ConfirmCard from 'forms/ConfirmCard'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountContext, FormContext } from 'utils/context'
import * as Sentry from '@sentry/browser'
import { getAuthedRequest } from 'utils/requests'
import LoadingSpinner from 'components/LoadingSpinner'
import { autoLienholderChoices, propertyLienholderChoices } from 'forms/utils/vars'

const LienholderPolicyConfirm = () => {
    const { t } = useTranslation()

    const { data, setFieldValue } = useContext(FormContext)
    const { policies, drivers } = useContext(AccountContext)
    const [isLoading, setIsLoading] = useState(true)

    const policyLienholders = data.policy_lienholder
    const policyNumber = lien => policies.find(p => p.policy_id === lien.policy_id).policy_number

    const lenderName = lender => { 
      if (lender.other_company) {
        return lender.other_company
      } else if (lender.private_lender_name){
        return lender.private_lender_name
      } else {
        return lender.financing_company
      }
    }
    
    const lenderAddress = lender => { 
      if (lender.other_company) {
        if (lender.company_unit_number) {
          return `${lender.company_unit_number} - ${lender.company_street_number} ${lender.company_street_name}, ${lender.company_city} ${lender.company_province} ${lender.company_postal_code}`
        } else {
          return `${lender.company_street_number} ${lender.company_street_name}, ${lender.company_city} ${lender.company_province} ${lender.company_postal_code}`
        }
        
      } else if (lender.private_lender_name){
        return lender.private_lender_address
      } else {
        return `${lender.financing_address} ${lender.financing_municipality}, ${lender.financing_province} ${lender.financing_postal_code}`
      }
    }

    useEffect(() => {
      getAuthedRequest('/api/lineitems/')
        .then(res => {
          const current = res.find(li => li.id === data.id)
          if (current && current.policy_lienholder) {
            setFieldValue('policy_lienholder', current.policy_lienholder)
            setIsLoading(false)
          }
        })
        .catch(err => Sentry.captureException(err))
    }, [data.policy_lienholders])  // eslint-disable-line

    const lienholderChoice = (lob, choice) => {
      if(lob === 'auto') {
        return autoLienholderChoices.find(l => l[0] === choice)
      } else {
        return propertyLienholderChoices.find(l => l[0] === choice)
      }
    }

    const namedInsuredName = (policy_lienholder, namedInsured) => {
      if (namedInsured === 'other') {
        return policy_lienholder.named_insured_other
      }
      const driver = drivers.find(d => String(d.driver_id) === namedInsured)
      return driver ? driver.full_name : namedInsured
    }

  return (
    <>
      {isLoading && 
          <div className="LoadingHolder"><LoadingSpinner /></div>}
        
      {!isLoading ? 
      <>
        {policyLienholders.map((l, i) => (
          <ConfirmCard key={i} title={t("Policy {{policy}}", {policy: policyNumber(l)})}id={i} slug="lienholder-details">
            <ul className="InfoList">
              <li>
                <small>{t('Named Insured')}</small>
                {l.named_insured.map((n, i) => (
                  <div key={i}>{namedInsuredName(l, n)}</div>
                ))}
              </li>
              <li>
                <small>{t('Lender change')}</small>
                {lienholderChoice(l.lob, l.lienholder_choices)[1]}
              </li>
              <li>
                <small>{t('Lender Name')}</small>
                {lenderName(l)}
              </li>
              <li>
                <small>{t('Lender Address')}</small>
                {lenderAddress(l)}
              </li>
            </ul>
          </ConfirmCard>
        ))}
      </>
      : null} 
    </>
  )
}

export default LienholderPolicyConfirm
