import Requirements from 'forms/fieldsets/general/Requirements'
import LienholderAddConfirm from 'forms/fieldsets/policy/LienholderAddConfirm'
import LienholderAddGetStarted from 'forms/fieldsets/policy/LienholderAddGetStarted'
import LienholderDetails from 'forms/fieldsets/policy/LienholderDetails'
import MultiPageForm from 'forms/MultiPageForm'
import React from 'react'

const LienholderAdd = () => {
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="lienholder-add" />
      <LienholderAddGetStarted path="get-started"/>
      <LienholderDetails path="lienholder-details"/>
      <LienholderAddConfirm path="confirm" />
    </MultiPageForm>
  )
}
                               
export default LienholderAdd
