import { addDays, startOfDay } from 'date-fns'
import DateChooser from 'forms/fields/DateChooser'
import Policies from 'forms/fields/Policies'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LienholderRemoveGetStarted = () => {
    const { t } = useTranslation()
  
  return (
    <fieldset>
      <Policies
        name="requested_policies"
        label={t("Which policies would you like to remove a lienholder/lessor/mortgage or line of credit?")}
        AllowMultiple
      />
      <DateChooser
        minDate={startOfDay(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t("When do you want to remove this lienholder/lessor/mortgage or line of credit?")}
        IncludeYear
      />
      <p>{t("Please note, changes cannot be made outside of 30 days.")}</p>
    </fieldset>
  )
}

export default LienholderRemoveGetStarted
