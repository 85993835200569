import RequestDisclaimer from 'components/RequestDisclaimer'
import ConfirmCard from 'forms/ConfirmCard'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormContext } from 'utils/context'
import RequesterDetails from '../general/RequesterDetails'
import LienholderPolicyConfirm from './LienholderPolicyConfirm'
import { titleCase } from 'utils/helpers'
import { lienholderRemoveReason } from 'forms/utils/vars'

const LienholderRemoveConfirm = () => {
    const { t } = useTranslation()
    let { data } = useContext(FormContext)

    let reason = lienholderRemoveReason.find(r => r[0] === data['reason_for_change'])

  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("Reason for change")} slug="lienholder-details" id={data.id}>
        <ul className="InfoList">
          <li>
            <small>{t('Reason for change')}</small>
            {data['reason_for_change'] === 'other' ? data['reason_for_change_other'] : titleCase(reason[1])}
          </li>
        </ul>
      </ConfirmCard>
      <LienholderPolicyConfirm/>
    </fieldset>
  )
}

export default LienholderRemoveConfirm
