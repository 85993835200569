import classNames from 'classnames'
import useField from 'forms/utils/useField'
import React, { useContext } from 'react'
import { AccountContext } from 'utils/context'
import Label from './Label'
import FieldError from './FieldError'
import { useTranslation } from 'react-i18next'

const PolicyNamedInsured = ({name, label, DefaultValue, ChangeCallback, FocusCallback, policyId, BlurCallback, Other, lienData}) => {

  const { t } = useTranslation()
  const { drivers } = useContext(AccountContext)
  const { value, onChange, onFocus, onBlur, active, touched, error } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback)
  
  const arrayValue = (typeof value === 'object' && value) || []

  const cls = classNames('field radio stacked', {
    active: active,
    touched: touched,
    'has-error': error,
  })

  let choices = []

  drivers.forEach(d => {
    if (Number(d.policy) === policyId) {
      choices.push([d.driver_id, `${d.full_name}`])
    }
  })

  if(choices && Other){
    choices = [...choices, ['other', t('Other')]]
  }
    
  const containsDriver = driver => {
    let drivers = []
    if (lienData['named_insured']?.length) {
      drivers = lienData['named_insured'].filter(d => d === String(driver[0]))
    } else {
      drivers = arrayValue.filter(d => d === String(driver[0]))
    }
    return drivers.length > 0
  }

  const handleChange = e => {
    const driver = e.target.name
    let next = [...arrayValue]
    if (next.indexOf(driver) === -1) {
      next.push(driver)
    } else {
      next = next.filter(d => d !== driver)
    }
    onChange({ target: { value: next } })
  }
  
  const selectedInsured = driver => {
    return lienData['named_insured']?.includes(driver)
  }

  return (
    <div id={`${name}-field`} className={cls} data-testid={name}>
      <Label label={label} name={name} />
      <div className="field-wrapper">
        <div className="choices">
          {choices.map((c, i) => (
            <Label key={i} secondaryLabel className={containsDriver(c) ? 'checked clearfix ': 'clearfix'}>
              <input
                name={c[0]}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                type="checkbox"
                checked={selectedInsured(c[0])}
              />
              <span>{c[1]}</span>
            </Label>
          ))}
        </div>
      </div>
      <FieldError error={error} name={name} />
    </div>
  )
}

export default PolicyNamedInsured
