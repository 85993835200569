import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import SimpleForm from 'forms/SimpleForm'
import Text from 'forms/fields/Text'
import Link from 'components/Buttons/Link'
import { AppContext } from 'utils/context'
import { emailAddress } from 'forms/utils/cleans'
import { loginError } from 'site/Login/vars'
import { useTranslation } from 'react-i18next'
import Tile from 'components/Tile'
import WarningIcon from 'utils/icons/Warning'
import LoadingSpinner from 'components/LoadingSpinner'
import './style.scss'

const LoginForm = () => {

  const { t } = useTranslation()
  const history = useHistory()
  const { setAuthed, setSessionStart, showMaintenance } = useContext(AppContext)

  const loginSuccess = res => {
    setSessionStart()
    if (res.user_id) {
      Cookies.set('user_id', res.user_id)
      setAuthed(true)
    }
  }

  const loginNotActive = (err, setFormError) => {
    if (err.error === 'Account is not active') {
      history.push('/resend-activation')
    } else if (err.error === 'Could not authenticate user') {
      setFormError(loginError)
    }
  }

  return showMaintenance ? (
    <>
      <p>{t('Login is currently disabled')}</p>
      <Tile className="under-maintenance-warning" Warning Icon={WarningIcon} IconName="WarningIcon" SmallIcon>
        <small>
          {t('Please be aware that we are performing routine maintenance on the site. Please try again later.')}
        </small>
      </Tile>
    </>
    ) : (
      <div className={`login-form-wrapper ${showMaintenance === null ? 'is-loading' : ''}`}>
        {showMaintenance === null && (
          <div className="LoadingHolder login-overlay"><LoadingSpinner /></div>
        )}
        <SimpleForm SubmitUrl="/api/users/login/" SubmitLabel={t('Log In')} SuccessCallback={loginSuccess} ErrorCallback={loginNotActive} SecondaryButton={<Link className="float-right" to="/forgot-password" Transparent Pill>{t('Forgot Password')}</Link>}>
          <Text
            name="email"
            label={t('Email Address')}
            cleans={[emailAddress]}
            autocomplete="username"
          />
          <Text
            name="password"
            label={t('Password')}
            type="password"
            autocomplete="current-password"
          />
        </SimpleForm>
      </div>
    )
}

export default LoginForm