import Requirements from 'forms/fieldsets/general/Requirements'
import LienholderReplaceConfirm from 'forms/fieldsets/policy/LienholderReplaceConfirm'
import LienholderReplaceGetStarted from 'forms/fieldsets/policy/LienholderReplaceGetStarted'
import LienholderDetails from 'forms/fieldsets/policy/LienholderDetails'
import MultiPageForm from 'forms/MultiPageForm'
import React from 'react'

const LienholderReplace = () => {
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="lienholder-replace" />
      <LienholderReplaceGetStarted path="get-started"/>
      <LienholderDetails path="lienholder-details"/>
      <LienholderReplaceConfirm path="confirm" />
    </MultiPageForm>
  )
}
                               
export default LienholderReplace
