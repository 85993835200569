import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from 'utils/context'
import PolicyLienholder from './PolicyLienholder'
import * as Sentry from '@sentry/browser'
import LoadingSpinner from 'components/LoadingSpinner'
import { getAuthedRequest } from 'utils/requests'

const PolicyLienholders = () => {

  const { data, setFieldValue } = useContext(FormContext)
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    getAuthedRequest('/api/lineitems/')
      .then(res => {
        const current = res.find(li => li.id === data.id)
        if (current && current.policy_lienholder) {
          setFieldValue('policy_lienholder', current.policy_lienholder)
          setIsLoading(false)
        }
      })
      .catch(err => Sentry.captureException(err))
  }, [data.policy_lienholders])  // eslint-disable-line
    
  return (
    <div>
      {isLoading && 
        <div className="LoadingHolder"><LoadingSpinner /></div>}
      
      {!isLoading ? 
      <div data-testid="fetch-success">{
        data.policy_lienholder.length > 0 ? 
        data.policy_lienholder.map(p =>
          <div key={p.policy_id} className='field'> 
            <PolicyLienholder
              policyId={p.policy_id}
              lienholderData = {p}
            />
          </div>
        ) : null
      }</div>
      : null} 
    </div>
  )
}

export default PolicyLienholders
