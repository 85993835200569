import React, { useContext } from 'react'
import Text from 'forms/fields/Text'
import OnLicenceNumber from 'forms/fields/OnLicenceNumber'
import Select from 'forms/fields/Select'
import Radio from 'forms/fields/Radio'
import PolicyVehicles from 'forms/fields/PolicyVehicles'
import { relationshipChoices, maritalChoices, authChoices, driverTypeChoices } from 'forms/utils/vars'
import { FormContext } from 'utils/context'
import { titleCase } from 'utils/helpers'
import { ontarioLicence, licenceAndLastName, validDateFromLicence } from 'forms/utils/cleans'
import { useTranslation, Trans } from 'react-i18next'
import { dobAndGenderFromLicence } from 'forms/utils/helpers'

const OnInformation = ({vehicles}) => {

  const { t } = useTranslation()
  let { data, setFieldValue, setFieldError, clearFieldError, clearAllErrors } = useContext(FormContext)

  const levelOfAuthority = data['first_name'] ? <Trans i18nKey="driverLevelAuthority" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="generalLevelAuthority" />
  const driverPrimaryVehicle = data['first_name'] ? <Trans i18nKey="driverPrimaryVehicle" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="generalPrimaryVehicle" />
  const howDoYouKnow = data['first_name'] ? <Trans i18nKey="howDoYouKnow" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="howDoYouKnowGeneral" />
  const outOfProvinceHistory = data['first_name'] ? <Trans i18nKey="outOfProvinceHistory" values={{ name: titleCase(data['first_name']) }} /> : <Trans i18nKey="outOfProvinceHistoryGeneral" />
  
  const setMarital = value => {
    if (data['marital_status']) {
      // no action..
    } else if (value === 'spouse') {
      setFieldValue('marital_status', 'm')
    } else {
      setFieldValue('marital_status', null)
    }
    if (value !== 'other') {
      setFieldValue('relationship_status_other', null)
      clearFieldError('relationship_status_other')
    }
  }

  const licAndLastName = value => {
    clearFieldError('drivers_licence_number')
    try {
      licenceAndLastName(data['drivers_licence_number'], value)
    } catch (e) {
      setFieldError('drivers_licence_number', e.message)
    }
  }

  const onLicenceChange = () => {
    clearAllErrors()
  }
  
  const onLicenceValues = () => {
    const value = data['drivers_licence_number']
    if (value) {
      try {
        ontarioLicence(value)
        licenceAndLastName(value, data['last_name'])
        validDateFromLicence(value)
        const { dob, gender } = dobAndGenderFromLicence(value)
        setFieldValue('date_of_birth', dob)
        setFieldValue('gender', gender)
      } catch (e) {
        setFieldError('drivers_licence_number', e.message)
      }
    }
  }
  
  return (
    <fieldset>
      <Text name="first_name" label={t('First Name')} />
      <Text name="last_name" label={t('Last Name')} ChangeCallback={licAndLastName} BlurCallback={onLicenceValues}/>
      <Select
        name="relationship_status"
        label={howDoYouKnow}
        choices={relationshipChoices}
        ChangeCallback={setMarital}
      />
      {data['relationship_status'] === 'other' && (
        <Text
          name="relationship_status_other"
          label={t("What is the relationship between you and the driver?")}
        />
      )}
      <OnLicenceNumber
        name="drivers_licence_number"
        label={t("Licence Number")}
        LastName={data['last_name']}
        ChangeCallback={onLicenceChange}
      />
      <Radio
        name="out_of_province_history"
        label={outOfProvinceHistory}
        choices={[[false, t('No')], [true, t('Yes')]]}
      />
      <Text name="date_of_birth" label={t("Date of Birth")} Hidden />
      <Text name="gender" label={t("Gender")} Hidden />
      <Select
        name="marital_status"
        label={t("Marital Status")}
        choices={maritalChoices}
      />
      <Radio
        name="authorization_status"
        label={levelOfAuthority}
        choices={authChoices}
      />
      <PolicyVehicles 
        name="vehicle_assignment"
        label={driverPrimaryVehicle}
        HelpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}
      />
      {vehicles.length === 1 && 
        <Radio
          label={t("Will they be primary or secondary driver of this vehicle?")}
          name="driver_type"
          choices={driverTypeChoices}
        />
      }
      <Text Optional name="additional_information" label={t('Are there any additional details you would like to provide regarding the use of your vehicles?')} />
    </fieldset>
  )
}

export default OnInformation