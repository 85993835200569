import React, { useEffect } from 'react';
import Button from 'components/Buttons/Button';
import Link from 'components/Buttons/Link';
import './style.scss';
import { useTranslation } from 'react-i18next'

const Modal = ({ isOpen, onClose, children }) => {
  const { t } = useTranslation()
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <div className="button-group">
            <Link onClick={onClose} to="/account/contact" className="modal-contact" Pill>
              {t('Contact Us')}
            </Link>
            <Button Pill onClick={onClose}>
              {t('Continue')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;