import { lienholderChangeReason, lienholderRemoveReason } from 'forms/utils/vars'
import React, { useContext } from 'react'
import Select from 'forms/fields/Select'
import { useTranslation } from 'react-i18next'
import { FormContext } from 'utils/context'
import Text from 'forms/fields/Text'
import PolicyLienholders from '../general/PolicyLienholders'

const LienholderDetails = () => {
  const { t } = useTranslation()
  const { data } = useContext(FormContext)

  return (
    <>
      <Select
        name="reason_for_change"
        choices={data.type === 'lienholder_remove' ? lienholderRemoveReason : lienholderChangeReason}
        label={t('Reason for Change')}
      />
      {data['reason_for_change'] === 'other' &&
        <Text
          name="reason_for_change_other"
          label={t('Other reason')}
        />
      }
      <div className='padding-m1-bottom'>{t('Please provide information for each policy below.')}</div>
      <PolicyLienholders/>
    </>
  )
}

export default LienholderDetails
