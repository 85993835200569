import React, { useState, useContext, useEffect } from 'react'
import classNames from 'classnames'
import useField from 'forms/utils/useField'
import Label from './Label'
import Button from 'components/Buttons/Button'
import FieldError from './FieldError'
import { FormContext } from 'utils/context'
import { useTranslation } from 'react-i18next'

const StringList = ({ filtered = [], setTypeAheadValue }) => {
  
  const { t } = useTranslation()
  return (
    <ul className="typeahead-list zebra-list">
      {filtered.length > 0 ? filtered.map(i => (
        <li key={i}>
          <Button onClick={e => setTypeAheadValue(e, i)}>{i}</Button>
        </li>
      )) : (
        <li className="no-matches form-error">
          {t('No match found.')}
        </li>
      )}
    </ul>
  )
}

const TypeAhead = ({ id, name, type, label, placeholder, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, HelpCopy, className, Nested, cleans, stringList, setTypeAheadValue }) => {
  
  const { data, setFieldValue } = useContext(FormContext)
  const [str, setStr] = useState('')

  const { onFocus, onBlur, active, touched, error, clearFieldError } = useField(name, DefaultValue, ChangeCallback, FocusCallback, BlurCallback, Nested, cleans)
  
  const getHelp = () => {
    if (typeof HelpCopy === 'function') {
      return <HelpCopy />
    } else {
      return <span className="help-copy">{HelpCopy}</span>
    }
  }

  const getFilteredList = () => {
    return stringList.filter(x =>
      x.toLowerCase().indexOf(str.toLowerCase()) !== -1
    )
  }

  const showList = () => {
    if (str !== '') {
      if (data[name]) {
        if (data[name].toLowerCase() === str.toLowerCase()) {
          return false
        }
      }
      return true
    }
    return false
  }

  const typeAheadItemClick = (e, value) => {
    e.preventDefault()
    setStr(value)
    setTypeAheadValue(value)
  }

  useEffect(() => {
    if (getFilteredList().length === 1) {
      if (getFilteredList()[0].toLowerCase() === str) {
        if (data[name] !== str) {
          setFieldValue(name, getFilteredList()[0])
        }
      }
    }
  }, [str]) // eslint-disable-line

  useEffect(() => {
    if (data[name]) {
      setStr(data[name])
    }
    if (DefaultValue) {
      setStr(DefaultValue)
    }
  }, []) // eslint-disable-line

  const classes = classNames('field text typeahead', {
		[className]: className,
		'active': active,
		'touched': touched,
    'has-error': error,
    'list-visible': showList(),
  })

  const typeaheadChange = e => {
    if (error) clearFieldError(name)
    setStr(e.target.value)
  }

	return (
    <div id={`${name}-field`} className={classes} data-testid={name}>
			<Label label={label} name={name} />
			<div className="field-wrapper">
				<input
	        id={id || name}
	        name={name}
	        value={str}
	        onChange={typeaheadChange}
	        onFocus={onFocus}
	        onBlur={onBlur}
	        type={type || 'text'}
          placeholder={placeholder}
          autoComplete="off"
	      />
        {showList() &&
          <StringList
            filtered={getFilteredList()}
            setTypeAheadValue={typeAheadItemClick}
          />
        }
			</div>
      <FieldError error={error} name={name} />
        {HelpCopy && getHelp()}
		</div>
	)
}

export default TypeAhead