import Requirements from 'forms/fieldsets/general/Requirements'
import LienholderRemoveConfirm from 'forms/fieldsets/policy/LienholderRemoveConfirm'
import LienholderRemoveGetStarted from 'forms/fieldsets/policy/LienholderRemoveGetStarted'
import LienholderDetails from 'forms/fieldsets/policy/LienholderDetails'
import MultiPageForm from 'forms/MultiPageForm'
import React from 'react'

const LienholderRemove = () => {
  return (
    <MultiPageForm>
      <Requirements path="requirements" requestType="lienholder-remove" />
      <LienholderRemoveGetStarted path="get-started"/>
      <LienholderDetails path="lienholder-details"/>
      <LienholderRemoveConfirm path="confirm" />
    </MultiPageForm>
  )
}
                               
export default LienholderRemove
